export const data = [
  {
    name: "Fintech",
    quantity: 8,
  },
  {
    name: "Delivery",
    quantity: 1,
  },
  {
    name: "Delivery",
    quantity: 3,
  },
  {
    name: "Fintech",
    quantity: 2,
  },
  {
    name: "Delivery",
    quantity: 5,
  },
];
