import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";

import { styles as mainStyles } from "../../../styles";

interface IProps {
  openModal: Dispatch<SetStateAction<boolean>>;
  state: boolean;
  hook: any;

  interestedOrInvest: boolean;
}

export const Modal: FC<IProps> = ({ openModal, hook, interestedOrInvest }) => {
  const investValues = hook.getValues();

  let usersToShow = [];

  if (interestedOrInvest === false) {
    usersToShow = investValues.usersWantToInvest;
  }
  if (interestedOrInvest === true) {
    usersToShow = investValues.usersInterested;
  }

  const handleClose = () => {
    openModal(false);
  };

  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(19, 13, 16, 0.8)",
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          padding: "40px 56px",
          width: "728px",
          maxHeight: "688px",
          minHeight: "200px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
          <ThumbUpIcon sx={{ ...mainStyles.Icons }} />
          <Box
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Typography sx={{ ...mainStyles.title }}>
              {interestedOrInvest
                ? "Miembros interesados:"
                : "Miembros invirtiendo:"}
            </Typography>
            <Box
              style={{
                overflow: "auto",
                minHeight: "200px",
                maxHeight: "500px",
                marginTop: "10px",
                padding: "9px 0px",
              }}
            >
              {usersToShow ? (
                usersToShow.map((item: any, index: number) => {
                  return (
                    <Box key={index} style={{ overflow: "auto" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          overflow: "auto",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px" }}>
                          {item.user.name}
                        </Typography>
                      </Box>
                      <hr />
                    </Box>
                  );
                })
              ) : (
                <>
                  <Typography>Aún no hay inversionistas interesados</Typography>
                  <hr />
                </>
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ ...mainStyles.actionsButtons }}
              >
                Cerrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
