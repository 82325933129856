import { useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { DashboardLayout } from "../../components/DashboardLayout";
import { ENotificationType } from "../../components/DashboardLayout/NotificationCard/constants";
import { ProviderScroll } from "../../hooks/useScroll";
import {
  addNotification,
  clearNotification,
} from "../../redux/actions/notifications";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { getNotifications } from "../../services/users";

import { ERoles } from "./role-catalog";
import routes from "./routes";

const FilterRouter = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { refreshNotifications } = useSelector(
    (store) => store.notificationReducer,
  );

  useEffect(() => {
    getNotifications().then(({ data }) => {
      dispatch(clearNotification());
      data.forEach((element: ENotificationType) => {
        dispatch(addNotification(element));
      });
    });
    return () => {
      dispatch(clearNotification());
    };
  }, [dispatch, refreshNotifications]);

  const listRoutes = routes
    .flatMap((route) => (route.children ? route.children : route))
    .filter((route) => route.roles.includes(user?.role?.code as ERoles));

  return (
    <Router>
      <ProviderScroll>
        <DashboardLayout>
          <Switch>
            {listRoutes.map((route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.route}
                component={route.component as any}
              />
            ))}
            <Route path="*" render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </DashboardLayout>
      </ProviderScroll>
    </Router>
  );
};

export default FilterRouter;
