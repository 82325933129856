import { yupResolver } from "@hookform/resolvers/yup";
import BallotIcon from "@mui/icons-material/Ballot";
import { Box, Button, Divider, Grid, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import SplitButton from "../../../../../../components/SplitButton/SplitButton";
import { EStatusReleasedOpportunity } from "../../../../../../constants";
import { useLoading } from "../../../../../../hooks/useLoading";
import { changeEdit } from "../../../../../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import {
  getStartupDetailEvaluationsById,
  updateStartupDetailEvaluationsById,
} from "../../../../../../services/startup";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import mainStyles from "../../../../styles";
import Layout from "../../Layout/Layout";
import { RESPONSIVE_BUTTON } from "../../types";

import { FinalComment } from "./FinalComment/FinalComment";
import { InfoBar } from "./InfoBar/InfoBar";
import { ScoreCard } from "./ScoreCard/ScoreCard";
import {
  comments,
  editEvaluationSchema,
  evaluations,
  FormType,
  IEvaluationResponse,
} from "./schemas";
import { USER_NOT_VALID_TO_EDIT_EVALUATION } from "./validaitons";

interface IProps {
  id: string;
  startupStatus: EStatusReleasedOpportunity;
}

export const EvaluationsAdmin = ({ id, startupStatus }: IProps) => {
  const [values, setValues] = useState<IEvaluationResponse>();
  const [change, setChange] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [average, setAverage] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { edit } = useSelector((store) => store.loggedUser);
  const dispatch = useDispatch();

  const { handleClose, handleOpen } = useLoading();

  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);

  const isVisibleEditButtton = !USER_NOT_VALID_TO_EDIT_EVALUATION.includes(
    user.role.code,
  );

  const evaluationPartner = values?.evaluations?.partner as any;
  const evaluationAnalyst = values?.evaluations?.analyst as any;
  const finalcomment = values?.comments as any;

  const allowEdit =
    ![
      EStatusReleasedOpportunity.CANCELLED,
      EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
      EStatusReleasedOpportunity.EXPIRED,
      EStatusReleasedOpportunity.INCOMPLETE,
      EStatusReleasedOpportunity.INVESTED,
      EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
      EStatusReleasedOpportunity.IN_REVIEW,
      EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
    ].includes(startupStatus) && edit;

  const hook = useForm<FormType>({
    resolver: yupResolver(editEvaluationSchema),
    mode: "all",
    defaultValues: {
      scoreEvaluation: evaluations,
      finalComment: comments,
    },
  });

  useEffect(() => {
    getStartupDetailEvaluationsById(id)
      .then((data) => {
        const res = data?.data?.payload?.info;
        setValues(res);
        const calc = (res.score.partner + res.score.analyst) / 2;
        setAverage(calc);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  function handleCancel() {
    dispatch(changeEdit(false));
    handleRefresh();
  }

  const roleType = change ? "ANALYST" : "PARTNER";

  async function onSubmit(data: any) {
    const date = new Date();
    const comments = [
      {
        user: finalcomment[0]._id,
        date: data.finalComment[0].date,
        comment: data.finalComment[0].comment,
      },
    ];

    const finalvalues = {
      comments,
      evaluations: data.scoreEvaluation,
      date,
      type: roleType,
    };

    try {
      handleOpen();
      await updateStartupDetailEvaluationsById(id, finalvalues);
    } catch (error) {
      displayToast("error", "Subo un error al actualizar");
    } finally {
      dispatch(changeEdit(true));
      handleRefresh();
      handleClose();
      handleCancel();
    }
  }
  const menu = (
    <Box display={"flex"} gap={"8px"}>
      <Button variant={"outlined"} onClick={handleCancel}>
        Cancelar
      </Button>
      <Button variant={"contained"} onClick={hook.handleSubmit(onSubmit)}>
        Guardar
      </Button>
    </Box>
  );

  const handleClosed = () => {
    dispatch(changeEdit(true));
  };

  return (
    <Layout>
      <Layout.Button>
        {isVisibleEditButtton &&
          !breakpoint &&
          (!edit ? (
            <Button
              variant="contained"
              onClick={() => handleClosed()}
              sx={{ ...mainStyles.actionsButtons }}
            >
              Editar
            </Button>
          ) : (
            menu
          ))}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          {breakpoint && (
            <Grid
              xs={12}
              item
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {!edit ? (
                <SplitButton
                  title="Editar"
                  isMobile
                  icon={<BallotIcon />}
                  options={[
                    {
                      label: "Editar",
                      visible: true,
                      onClick: handleClosed,
                    },
                  ]}
                />
              ) : (
                menu
              )}
            </Grid>
          )}
          <InfoBar
            average={average}
            change={change}
            evaluationAnalystCreatedAt={evaluationAnalyst?.createdAt}
            evaluationAnalystName={evaluationAnalyst?.name}
            evaluationAnalystUpdatedAt={evaluationAnalyst?.updatedAt}
            evaluationPartnerCreatedAt={evaluationPartner?.createdAt}
            evaluationPartnerName={evaluationPartner?.name}
            evaluationPartnerUpdatedAt={evaluationPartner?.updatedAt}
            setChange={setChange}
            isEditable={!edit}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", gap: "50px 80px", flexWrap: "wrap" }}
          >
            {change
              ? evaluationAnalyst?.evaluations?.map(
                  (data: any, index: number) => (
                    <ScoreCard
                      key={index}
                      title={data.key}
                      score={data.score}
                      comment={data.comment}
                      editable={allowEdit}
                      indicator={index}
                      hook={hook}
                      refresh={refresh}
                    />
                  ),
                )
              : evaluationPartner?.evaluations?.map(
                  (data: any, index: number) => (
                    <ScoreCard
                      key={index}
                      title={data.key}
                      score={data.score}
                      comment={data.comment}
                      editable={allowEdit}
                      indicator={index}
                      hook={hook}
                      refresh={refresh}
                    />
                  ),
                )}
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {finalcomment?.map((data: any, index: number) => (
            <FinalComment
              key={index}
              comment={data?.comment}
              editable={!edit}
              hook={hook}
              indicator={index}
              refresh={refresh}
            />
          ))}
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
