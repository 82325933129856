import { ArrowForward, Search } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import { TextFieldAutoComplete } from "../../components/Inputs/TextInput/styled";
import MainLayout from "../../components/MainLayout";
import { fetchUserByRole } from "../../services/users";
import { ERolesCode } from "../routes/role-catalog";

import DirectoryPagination from "./components/DirectoryPagination";
import InvestorCard from "./components/InvestorCard";
import { directoryInvestorsStyles } from "./styles";

const DirectoryInvestors = () => {
  const [users, setUsers] = useState<any>([]);
  const ref = useRef(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState<number>(10);

  const [searchText, setSearchText] = useState<string>("");

  const currentCards = handleSearchUsers(users, searchText, page, cardsPerPage);
  const isFiltering = Boolean(searchText);

  useEffect(() => {
    const partner = fetchUserByRole(ERolesCode.PARTNER);
    const analyst = fetchUserByRole(ERolesCode.ANALYST);
    const administrator = fetchUserByRole(ERolesCode.ADMINISTRATOR);
    Promise.all([partner, analyst, administrator])
      .then((data) => {
        const users = data.flatMap((i) => i.data.payload);
        setUsers(users);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  function handleSearchUsers(
    params: any[],
    search: string,
    page: number,
    size: number,
  ) {
    const start = page * size;
    const end = start + size;

    return params
      .filter(
        (i) =>
          `${i.firstName} ${i.lastName}`
            .toLowerCase()
            .includes(search.toLocaleLowerCase()) || !search,
      )
      .slice(start, end);
  }

  function handleSearch(event: any) {
    setSearchText(event.currentTarget.value);
    setPage(0);
  }

  function handleChangePage(num: number) {
    setPage(num);
  }

  return (
    <Box ref={ref}>
      <PageTitle title="Directorio de Equipo AngelHub" />
      <MainLayout
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={directoryInvestorsStyles}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <TextFieldAutoComplete
                value={searchText}
                onChange={handleSearch}
                label="Buscar"
                placeholder="Ingresa un nombre para buscar..."
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: "#8BB3D8" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <ArrowForward sx={{ color: "#8BB3D8" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Typography className="totalResult">
            <strong>{currentCards.length} </strong>
            Resultados
          </Typography>
          {loading && (
            <Box
              minHeight={"60vh"}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
          {!currentCards.length && !loading && (
            <Typography className="noResults">
              No hay ningún registro
            </Typography>
          )}
          <Grid container spacing={2}>
            {Boolean(currentCards.length) &&
              !loading &&
              currentCards.map((user: any, index: number) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <InvestorCard
                    role={user.role.code}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    position={user.position}
                    avatar={user.avatar}
                    id={user._id}
                  />
                </Grid>
              ))}
          </Grid>
          {currentCards.length > 0 && (
            <DirectoryPagination
              count={isFiltering ? currentCards.length : users.length}
              page={page}
              setPage={handleChangePage}
              setCardsPerPage={setCardsPerPage}
              cardsPerPage={cardsPerPage}
            />
          )}
        </Box>
      </MainLayout>
    </Box>
  );
};

export default DirectoryInvestors;
