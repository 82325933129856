//DON'T FORGET ADD PROP spacing={3} in the main Grid of your TAB

export const styles = {
  container: { width: "100%", height: "100%", padding: "44px 24px 40px 24px" },
  actionsButtons: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
  },
  actionsMobileButtons: {
    backgroundColor: "#74C24A",
    width: "96px",
    color: "white",
  },
  Info: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    flexWrap: "no-wrap",
    overflowWrap: "anywhere",
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  Icons: {
    display: "flex",
    alignSelf: "flex-start",
    background: "#C0DDE7",
    borderRadius: "100%",
    width: "32px",
    height: "32px",
    padding: "6px",
    color: "#56686e",
  },
  title: {
    fontWeight: "400",
    lineHeight: "28px",
    fontSize: "20px",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "28px",
    },
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "24px",
      fontWeight: "500",
    },
    strong: {
      fontSize: "28px",
      fontWeight: "500",
    },
  },
  amounts: {
    fontWeight: "800",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#515151",
    "@media (max-width: 600px)": {
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "28px",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  details: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: "12px",
  },
  detailsText: {
    fontWeight: "400",
    display: "flex",
    minHeight: "44px",
    width: "auto",
    alignItems: "center",
    fontSize: "16px",
    gap: "4px",
    detailsDates: {
      fontSize: "16px",
      color: "#676767",
    },
    detailsCloseDate: {
      fontSize: "16px",
      color: "#676767",
      "@media (max-width: 447px)": {
        marginLeft: "24px",
      },
    },
  },
  detailsIcons: {
    color: "#4A7C2F",
    width: "20px",
  },
};
