import InsightsIcon from "@mui/icons-material/Insights";
import PaymentsIcon from "@mui/icons-material/Payments";
import SavingsIcon from "@mui/icons-material/Savings";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

import { EStatusReleasedOpportunity } from "../../../../../../../constants";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { currencyFormat } from "../../../../../../../utils/helpers/currencyFormatter";
import { ERolesCode } from "../../../../../../routes/role-catalog";
import { VALID_STATUS_TO_SHOW_MOIC } from "../../../../../validations";
import { FormType } from "../../../../MainCard/types";
import { styles as mainStyles } from "../../../styles";
import { Modal } from "../Modal/Modal";
import { styles } from "../styles";
import { VALID_ROLES_TO_INVEST } from "../validation";

interface IInvestmentRoundCard {
  hook?: any;
  userIsInvesting: any;
}

export const InvestCard: FC<IInvestmentRoundCard> = ({
  hook,
  userIsInvesting,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const mainCardHook = useFormContext<FormType>();

  const usersInvesting = mainCardHook.watch("usersWantToInvest") || [];

  const isClosed =
    mainCardHook.watch("status") ===
    EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT;

  const accumulatedAmount = usersInvesting.reduce(
    (accumulator: number, item: any) => item.money + accumulator,
    0,
  );

  const myInvestCommitment =
    usersInvesting.find((item: any) => item.user._id === user._id) || 0;

  const userHasValidRoleToInvest = VALID_ROLES_TO_INVEST.includes(
    user.role.code,
  );

  const isValidToShowMoic = VALID_STATUS_TO_SHOW_MOIC.includes(
    mainCardHook.watch("status") as EStatusReleasedOpportunity,
  );

  return (
    <Grid item xs={12} md={6}>
      {openModal && (
        <Modal
          openModal={setOpenModal}
          state={openModal}
          interestedOrInvest={false}
          hook={mainCardHook}
        />
      )}
      <Grid
        item
        xs={11.3}
        sx={{
          ...mainStyles.Info,
          ...styles.boxCardContainer,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", gap: "5px" }}>
          <SavingsIcon sx={{ ...mainStyles.Icons }} />
          <Box>
            <Typography sx={{ ...mainStyles.title }}>
              Monto recaudado:
            </Typography>
            <Typography sx={{ ...mainStyles.amounts }}>
              <strong>{currencyFormat(accumulatedAmount)}</strong>{" "}
              <span>USD</span>
            </Typography>
          </Box>
        </Box>
        {userHasValidRoleToInvest && (
          <Box sx={{ display: userIsInvesting ? "flex" : "none", gap: "5px" }}>
            <InsightsIcon sx={{ ...mainStyles.Icons }} />
            <Box>
              <Typography sx={{ ...mainStyles.title }}>
                {userIsInvesting
                  ? "Mi monto de inversion"
                  : "Mi compromiso de inversión"}
              </Typography>
              <Typography sx={{ ...mainStyles.amounts }}>
                <strong>{currencyFormat(myInvestCommitment.money)}</strong>{" "}
                <span>USD</span>
              </Typography>
              {isValidToShowMoic && (
                <Tooltip title="*En base a valuación de última ronda; este valor puede variar en una transacción real de salida y se le debe aplicar la cascada de distribución correspondiente.">
                  <Stack direction={"row"} gap={1} alignItems="center">
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: " 16px",
                        lineHeight: " 24px",
                        color: "#343434",
                      }}
                    >
                      Rendimiento:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: " 18px",
                        lineHeight: " 24px",
                        color: "#397386",
                      }}
                    >
                      {`${
                        isFinite(hook.watch("moic"))
                          ? `${hook.watch("moic")}x`
                          : "-"
                      }`}
                    </Typography>
                  </Stack>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
        <PaymentsIcon sx={{ ...mainStyles.Icons }} />
        <Box style={{ width: "80%" }}>
          <Typography sx={{ ...mainStyles.title, marginBottom: "8px" }}>
            Miembros invirtiendo:
          </Typography>
          <Box sx={{ ...styles.boxListContainer }}>
            <Box>
              {usersInvesting.length >= 1 ? (
                usersInvesting.map((item: any, index: number) => {
                  return (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px" }}>
                          {item.user.name}
                        </Typography>

                        <Typography
                          style={{ color: "#4A7C2F", fontSize: "16px" }}
                        >
                          {user.role.code === ERolesCode.MEMBER ||
                          user.role.code === ERolesCode.STARTUP
                            ? ""
                            : currencyFormat(item.money)}
                        </Typography>
                      </Box>
                      <hr />
                    </Box>
                  );
                })
              ) : (
                <>
                  <Typography sx={{ fontSize: "16px" }}>
                    Aún no hay inversionistas interesados{" "}
                  </Typography>
                  <hr />
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ ...styles.buttonSeeAllContainer }}>
            {usersInvesting?.length > 5 && (
              <Button
                onClick={() => setOpenModal(!openModal)}
                sx={{ ...styles.seeAll }}
              >
                Ver todos
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
