import { Ballot } from "@mui/icons-material";
import { Button, SxProps } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../components/SplitButton/SplitButton";
import { OptionButtonSplit } from "../../../../../../components/SplitButton/types";
import { EStatusReleasedOpportunity } from "../../../../../../constants";
import { useLoading } from "../../../../../../hooks/useLoading";
import { openModal } from "../../../../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import {
  opportunitiesReleasedChangeIntention,
  opportunitiesReleasedChangeStatus,
} from "../../../../../../services/opportunitiesReleased";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import { VALID_STATUS_TO_CLOSE } from "../../../../../OpportunitiesReleasedAdmin/validations";
import { ERolesCode } from "../../../../../routes/role-catalog";
import { FormType } from "../../types";
import {
  VALID_STATUS_TO_CANCEL,
  VALID_STATUS_TO_SWITCH_TO_NORMAL_BUTTON,
} from "../../validations";
import { InvestModalMainCard } from "../InvestModalMainCard/InvestModalMainCard";

interface IProps {
  changeMode: () => void;
  isMobile?: boolean;
}

const ButtonReadMode: React.FC<IProps> = ({ changeMode, isMobile }) => {
  const { id } = useParams() as { id: string };
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { handleClose: handleCloseLoading, handleOpen } = useLoading();
  const hook = useFormContext<FormType>();

  const status = hook.watch("status") as EStatusReleasedOpportunity;

  const isInInvestProcess =
    status === EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS;
  const isIncomplete = status === EStatusReleasedOpportunity.INCOMPLETE;
  const isClosed = status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT;
  const isOpenToInvest =
    status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT;
  const isExpired = status === EStatusReleasedOpportunity.EXPIRED;

  const isAdminAndPartner = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.SUPER_ADMIN,
    ERolesCode.PARTNER,
  ].includes(user.role.code);
  const isAdmin = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.PARTNER,
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ANALYST,
    ERolesCode.MEMBER,
  ].includes(user.role.code);

  const isMember = user.role.code === ERolesCode.MEMBER;

  const userIsInvesting = hook
    .watch("usersWantToInvest")
    .map((i) => i.user._id)
    .includes(user._id);

  function handleEditar() {
    changeMode();
  }

  function handleCloseInvestment() {
    setIsOpenModal(false);
  }

  function handleChangeInvestment() {
    setIsOpenModal(true);
  }

  async function handleRemoveInvestment() {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "¿Estas seguro que deseas retirar la inversion?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeIntention({
              startupId: id,
              type: "LEAVE_INVESTMENT",
              user: user._id,
            });
            const newValues = hook
              .watch("usersWantToInvest")
              .filter((i) => i.user._id !== user._id);
            hook.setValue("usersWantToInvest", newValues);
            displayToast("success", "Se retiro inversion correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo retirar inversion correctamente.",
            );
          }
          handleCloseLoading();
        },
      }),
    );
  }

  async function handleCancel() {
    dispatch(
      openModal({
        title: "Información",
        description:
          "Se cancelara la oportunidad y no se podrá reactivar. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeStatus({
              status: EStatusReleasedOpportunity.CANCELLED,
              startupId: id,
            });
            hook.setValue("status", EStatusReleasedOpportunity.CANCELLED);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  async function handleExpired() {
    dispatch(
      openModal({
        title: "Información",
        description: `La oportunidad se pondrá en estatus "Expirada". ¿Desea continuar?`,
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeStatus({
              status: EStatusReleasedOpportunity.EXPIRED,
              startupId: id,
            });
            hook.setValue("status", EStatusReleasedOpportunity.EXPIRED);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  async function handleCloseToInvest() {
    dispatch(
      openModal({
        title: "Información",
        description: "La oportunidad se cerrará. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeStatus({
              status: EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
              startupId: id,
            });
            hook.setValue(
              "status",
              EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
            );
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  async function handleInProgress() {
    dispatch(
      openModal({
        title: "Información",
        description: "La oportunidad se pondrá en revision. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeStatus({
              status: EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
              startupId: id,
              date: new Date(),
            });
            hook.setValue(
              "status",
              EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
            );
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  async function handleInvested() {
    dispatch(
      openModal({
        title: "Información",
        description: "La oportunidad se pondrá en revision. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeStatus({
              status: EStatusReleasedOpportunity.INVESTED,
              startupId: id,
            });
            hook.setValue("status", EStatusReleasedOpportunity.INVESTED);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  function handleOnSubmit(value: number) {
    const copy = hook.getValues("usersWantToInvest");
    const index = copy.findIndex((i) => i.user._id === user._id);
    copy[index].money = value;
    hook.setValue("usersWantToInvest", copy);
    setIsOpenModal(false);
  }

  if (!userIsInvesting && isMember) {
    return null;
  }

  const optionsButton: OptionButtonSplit[] = [
    {
      label: "Editar",
      visible:
        isAdmin ||
        (user.role.code === ERolesCode.STARTUP && user.startup._id === id),
      onClick: handleEditar,
    },
    {
      label: "Editar Inversion",
      visible: isMember,
      onClick: handleChangeInvestment,
    },
    {
      label: "Retirar Inversión",
      visible: isMember,
      onClick: handleRemoveInvestment,
    },
    {
      label: "Cancelar",
      visible:
        isIncomplete &&
        isAdminAndPartner &&
        VALID_STATUS_TO_CANCEL.includes(hook.watch("status") as any),
      onClick: handleCancel,
    },
    {
      label: "Expirada",
      visible: isOpenToInvest && !isExpired && isAdminAndPartner,
      onClick: handleExpired,
    },
    {
      label: "Cerrar",
      visible:
        (isOpenToInvest && !isClosed && isAdminAndPartner) ||
        (isAdminAndPartner &&
          VALID_STATUS_TO_CLOSE.includes(hook.watch("status") as any)),
      onClick: handleCloseToInvest,
    },
    {
      label: "En Proceso de Depósito",
      visible: isClosed && isAdminAndPartner,
      onClick: handleInProgress,
    },
    {
      label: "Invertida",
      visible: isInInvestProcess && isAdminAndPartner,
      onClick: handleInvested,
    },
  ];

  if (!status) {
    return null;
  }

  if (VALID_STATUS_TO_SWITCH_TO_NORMAL_BUTTON.includes(status)) {
    return (
      <Button
        sx={{ backgroundColor: "#74C24A", width: "162px", color: "white" }}
        variant="contained"
        onClick={handleEditar}
      >
        Editar
      </Button>
    );
  }

  return (
    <>
      <SplitButton
        title="Acciones"
        options={optionsButton}
        icon={<Ballot />}
        isMobile={isMobile}
      />
      {isOpenModal && (
        <InvestModalMainCard
          onSubmit={handleOnSubmit}
          onClose={handleCloseInvestment}
        />
      )}
    </>
  );
};

export default ButtonReadMode;
