import { Grid, TablePagination, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { getResourceLibrary } from "../../services/dashboard";

import CarouselResourceLibrary from "./CarouselResourceLibrary/CarouselResourceLibrary";
import FiltersResourceLibrary from "./FiltersResourceLibrary/FiltersResourceLibrary";
import styles from "./styles";

const ResourceLibrary = () => {
  //Pagination
  const [limitPage, setLimitPage] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [page, setCurrentPage] = useState<number>(0);

  //Filters
  const [category, setCategory] = useState<string[]>([]);
  const [typeContent, setTypeContent] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  //Others component
  const [ResourceLibraryItems, setResourceLibraryItems] = useState([]);
  const [Loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getResourceLibrary(page, limitPage, category, typeContent, searchText)
      .then(({ data: { data, meta } }) => {
        setResourceLibraryItems(data);
        setTotalResults(meta.pagination.total);
        setLimitPage(meta.pagination.pageSize);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, limitPage, category, typeContent, searchText]);

  const handleChangePage = (_: any, value: number) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (event: any) => {
    const value = event.target.value as number;
    setLimitPage(value);
  };

  function handleChangeFilterCategory(value: string[]) {
    setCurrentPage(0);
    setCategory(value);
  }
  function handleChangeFilterTypeContent(value: string[]) {
    setCurrentPage(0);
    setTypeContent(value);
  }
  function handleChangeSearch(value: string) {
    setCurrentPage(0);
    setSearchText(value);
  }

  return (
    <>
      <PageTitle title="Biblioteca de Recursos" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <Grid
          container
          sx={{
            padding: {
              md: "40px 72px 20px 72px",
              xs: "20px 16px 20px 16px",
            },
          }}
        >
          <Grid item xs={12}>
            <Typography sx={styles.title}>
              En este espacio podrás encontrar artículos de utilidad para que
              complementes tus habilidades y conocimiento dentro de AngelHub.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FiltersResourceLibrary
              category={category}
              setCategory={handleChangeFilterCategory}
              typeContent={typeContent}
              setTypeContent={handleChangeFilterTypeContent}
              searchText={searchText}
              setSearchText={handleChangeSearch}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction="row" marginY={"24px"}>
              <Typography sx={styles.numberText}>{totalResults}</Typography>
              <Typography sx={styles.resultsText}>Resultados</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CarouselResourceLibrary
              loading={Loading}
              items={ResourceLibraryItems}
            />
          </Grid>
          <Grid item xs={12}>
            {ResourceLibraryItems.length > 0 && (
              <Stack
                display="flex"
                direction="row"
                marginTop="16px"
                justifyContent={{ xs: "center", sm: "flex-end" }}
              >
                <TablePagination
                  page={page}
                  count={totalResults}
                  rowsPerPage={limitPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeLimit}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}–${to} de ${count !== -1 ? count : `mas de ${to}`}`
                  }
                  labelRowsPerPage="Posteos por página"
                  sx={{ border: "none", padding: "0", margin: "0" }}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
};

export default ResourceLibrary;
